//验证邮箱的规则
export function checkEmail(rule, value, cb){
    // 验证邮箱的正则表达式
    const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
    if (regEmail.test(value)) {
        return cb();
    }
    cb(new Error("请输入合法的邮箱"));
};

//验证手机号的校验规则
export function checkMobile(rule, value, cb){
    // 验证手机号的正则表达式
    const regMobile = /^(0|86|17951|)?(13[0-9]|15[0123456789]|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
    if (regMobile.test(value)) {
        return cb();
    }
    cb(new Error("请输入合法的手机号"));
};

//验证复购天数第一种方式
export function checkday(rule, value, cb){
    if (!value) {
         return cb();
    }else{
           setTimeout(() => {
             if (!Number.isInteger(value)) {
               cb(new Error('请输入数字值'));
             } else {
               if (value<15 || value>9999) {
                 cb(new Error('间隔设置区间要求“大于等于15天，小于等于9999天”'))
               } else {
                 cb();
               }
             }
           }, 1000);
       }
  };

//验证复购天数第二种方式
export function checkDay(rule, value, cb){
  if (!value) {
     return cb(new Error('复购间隔不能为空'));
  }else{
         setTimeout(() => {
           if (!Number.isInteger(value)) {
             cb(new Error('请输入数字值'));
           } else {
             if (value<15 || value>9999) {
               cb(new Error('间隔设置区间要求“大于等于15天，小于等于9999天”'))
             } else {
               cb();
             }
           }
         }, 1000);
     }
};

//验证文字描述是否全部为空格
export function allSpaces(rule, value, cb){
    //验证输入全部为空格的正则
    const regAllSpaces = /^[ ]+$/;
    if (regAllSpaces.test(value)) {
        return cb(new Error('不能全为空'));
    }else{
        cb();
    }
}



//验证是否为数字
export function isNumber(rule,value,cb){
  if (!value) {
    return cb(new Error('输入不能为空'));
 }else{
        setTimeout(() => {
          if (!Number.isInteger(value)) {
             cb(new Error('请输入数字值'));
          } else {
            if (value<=0) {
              cb(new Error('请输入正整数'))
            } else {
              cb();
            }
          }
        }, 1000);
    }
}


//验证人数
export function people(rule,value,cb){
  if (!value&&value!==0) {
    return cb(new Error('输入不能为空'));
 }else{
        setTimeout(() => {
          if (!Number.isInteger(value)) {
             cb(new Error('请输入数字值'));
          } else {
            if (value<0) {
              cb(new Error('请正确输入！'))
            } else {
              cb();
            }
          }
        }, 1000);
    }
}


export function isNum(rule, value, cb){//包含小数的数字
let regIsNum = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
if (!value) {
    cb(new Error('输入不能为空'));
} else if (!regIsNum.test(value)) {
    cb(new Error('请输入数字'));
} else {
    cb();
}
};


export function isNumTwo(rule, value, cb){//最多保留两位小数的数字
  let regIsNum = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
  if (!value) {
      cb(new Error('输入不能为空'));
  } else if (!regIsNum.test(value)) {
      cb(new Error('请输入正确的格式,可保留两位小数！'));
  } else {
      cb();
  }
}; 



// 登录注册
export function validateuser(rule, value, callback) {
  if (!value) {
      callback(new Error('请输入11位数手机号码'));
  } else {
      if (!/^1[345789]\d{9}$/.test(value)) {
          callback(new Error('请正确的手机号码'));
      } else {
          callback();
      }
  }
};
export function validatepassword(rule, value, callback) {
  if (value === '') {
      callback(new Error('请输入密码'));
  } else {
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/.test(value)) {
          callback(new Error('密码由字母和数字构成,需大于6位数'));
      } else {
          callback();
      }
  }
};
//图片验证码
export function validateyzm(rule, value, callback) {
  if (value === '') {
      callback(new Error('请输入右侧验证码'));
  } else {
      callback();
  }
};
//短信验证码
export function validatecode(rule, value, callback) {
  if (value === '') {
      callback(new Error('请输入短信验证码'));
  } else {
      callback();
  }
};
//qq验证
export function validaterepeatqq(rule, value, callback) {
  if (value === '') {
      callback(new Error('请输入qq号码'));
  } else {
      if (!/^[1-9][0-9]{4,10}$/.test(value)) {
          callback(new Error('输入的QQ格式有误'));
      } else {
          callback();
      }

  }
};
//邀请码
export function validaterepeatyqm(rule, value, callback) {
  if (value === '') {
      callback(new Error('请输入邀请码'));
  } else {
      callback();
  }
};

