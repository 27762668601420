<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card shadow="hover">
      <Title :title="title[0]"></Title>

      <div class="recharge_box">
        <el-tabs v-model="activeName">
          <!-- <el-tab-pane label="在线充值" name="first">
            <div class="external_box">
              <div class="line"></div>
              <div class="content" v-show="SM_payment==false">
                <div class="balance">
                  当前可用余额：
                  <span>￥1999.00</span> 元
                </div>

                <div class="recharge_form">
                  <el-form status-icon label-width="130px">
                    <div class="r_first">
                      <el-form-item label="请输入充值金额：" prop="rechargeAmount">
                        <el-input placeholder="请输入充值金额" class="ys">
                          <span slot="append">元</span>
                        </el-input>
                      </el-form-item>
                      <p class="tips">（注：金额必须为正整数）</p>
                    </div>
                    <div class="r_second">
                      <el-form-item label="请选择充值方式：" prop="rechargeMode">
                        <el-radio v-model="rechargeRadio" label="1" class="recharge_radio">
                          <img src="@/assets/images/Alipay.png" class="alipay" />
                        </el-radio>
                      </el-form-item>
                    </div>
                    <div class="r_third">
                      <el-button type="primary">立即支付</el-button>
                      <p>此操作为即时到账，请认真填写</p>
                    </div>
                  </el-form>
                </div>

                <div class="tips_box">
                      <img src="@/assets/images/tips_01.png" />
                      <span>温馨提示</span>
                      <div class="tips_list">
                          <p class="tip">1.在线充值付款成功之后即时到账,如果没有到账,等待几分钟即可更新。</p>
                          <p class="tip">2.在线充值支付平台会收取1%的手续费,并非本网站收取，请知悉。</p>
                      </div>
                </div>
              </div>


              <div class="content" v-show="SM_payment==true">
                    <div class="code_box">
                            <div class="top">
                                   <span class="code_title">请扫码支付</span>
                                   <el-button type="danger" size="mini" class="cancel_payment" @click="cancelPayment">取消支付</el-button>
                            </div>
                            <div class="middle">
                                    <img src="@/assets/images/charge_code.jpg" />
                            </div> 
                            <div class="bottom"> 
                                    <el-button type="primary">我已付款</el-button>
                                    <el-button type="warning" @click="paymentProblem">付款遇到的问题</el-button>
                            </div>
                    </div>
              </div>
            </div>
          </el-tab-pane> -->

          <el-tab-pane label="扫码充值" name="second">
             <div class="external_box">
              <div class="line"></div>
              <div class="content" >
                <div class="balance">
                  当前可用余额：
                  <span>￥{{codeInfo.money}}</span> 元
                </div>

                <div class="QR_code_box">
                       <img :src="codeInfo.img" />
                       <span>充值前请刷新一下</span>
                </div>

                <div class="recharge_form">
                  <el-form status-icon :model="rechargeForm" :rules="rechargeRules" ref="rechargeFormRef" label-width="140px">
                    <div class="r_first">
                      <el-form-item label="支付宝订单号：" prop="rechargeAmount">
                        <el-input placeholder="请输入支付宝订单号" class="ys" v-model="rechargeForm.rechargeAmount"></el-input>
                      </el-form-item>
                    </div>
                    <div class="r_second">
                      <el-form-item label="请选择充值方式：" prop="rechargeRadio">
                        <el-radio v-model="rechargeForm.rechargeRadio" label="1" class="recharge_radio">
                          <img src="@/assets/images/Alipay.png" class="alipay" />
                        </el-radio>
                      </el-form-item>
                    </div>
                    <div class="r_third">
                      <el-button type="primary" @click="QRCodeRecharge">提交充值</el-button>
                      <p>提交成功之后，系统将自动充值，无需手续费</p>
                    </div>
                  </el-form>
                </div>

                <div class="tips_box">
                      <img src="@/assets/images/tips_01.png" />
                      <span>温馨提示</span>
                      <div class="tips_list">
                          <p class="tip">1.先去支付宝扫描上方二维码支付转账到优讯****有限公司账户；</p>
                          <p class="tip">2.然后输入您转账的交易号（订单号）和金额，两者必须一致，否则无法充值；</p>
                          <p class="tip">3.打款成功后，请过3分钟后再提交，超过8小时提交无效。每天晚上23:55到0点系统五分钟整理数据不要充值；<span @click="courseDialogVisible = true">如何获取支付宝交易号（订单号）？</span></p>
                          <p class="tip">4.近期支付宝数据同步不稳定，如超过5分钟还提示订单号不存在，请联系客服。</p>
                      </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>


    <el-card shadow="hover">
           <Title :title="title[1]"></Title>
   
          <div class="recharge_record_box">
                 <div class="record_tips">
                     <img src="@/assets/images/tip.png" />
                     <p>平台仅保留近一个月记录明细，有需求请自行导出备份</p>
                 </div>

                 <!-- 列表区域  border加边框 stripe隔行变色 -->
                 <el-table :data="rechargeRecord.info" border stripe :header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}">
                   <el-table-column type="index" label="序号" width="50px"></el-table-column>
                   <el-table-column label="充值时间" prop="ctime"></el-table-column>
                   <el-table-column label="交易单号" prop="order"></el-table-column>
                   <el-table-column label="三方交易单号" prop="dsforder"></el-table-column>
                   <el-table-column label="充值金额（元）" prop="money" width="300px"></el-table-column>
                   <el-table-column label="充值状态" prop="state">
                     <template v-slot:default="scope">
                       <el-tag type="success" v-if="scope.row.state==1">支付成功</el-tag>
                       <el-tag type="danger" v-else>未支付</el-tag>
                     </template>
                   </el-table-column>
                   <el-table-column label="备注" prop="remarks"></el-table-column>
                 </el-table>

                 <!-- 分页区域 -->
                 <el-pagination
                   :current-page="queryInfo.pagenum"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :page-sizes="rechargeRecord.page_arr"
                   :page-size="queryInfo.pagesize" 
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="rechargeRecord.num"
                 ></el-pagination>
         </div>
    </el-card>


     <!--获取支付宝交易单号教程对话框 -->
    <el-dialog :visible.sync="courseDialogVisible" width="50%">
        <!--内容主体区域-->
        <img :src="codeInfo.rhhq" />
    </el-dialog>

  </div>
</template>

<script>  
import { mapState } from 'vuex'
import { allSpaces } from '@/utils/validate'
export default {
  name: "recharge",
  data() {
    return {
      title: ["充值", "充值记录"],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "余额管理",
          path: "",
          isClick: false
        },
        {
          id: 3,
          title: "充值",
          path: "recharge",
          isClick: true
        }
      ],
      //充值方式默认
      activeName: "second",
      //充值的表单数据
      rechargeForm: {
            rechargeAmount: "",//支付宝订单号
            rechargeRadio: "1",//控制充值渠道 默认支付宝
      },
      //绑定店铺表单的验证规则对象
      rechargeRules:{
        rechargeAmount: [
          {required: true, message: "请输入支付宝订单号", trigger:"blur"},
          { validator: allSpaces, trigger: "blur" }
        ],
        rechargeRadio: [
          { required: true, message: "请选择充值方式", trigger:"change"},
        ],
      },
      //获取充值记录列表的参数对象
      queryInfo: {
          // 当前的页数
          pagenum: 1,
          // 当前每一页显示多少条数据
          pagesize: 10
      },
      //控制获取支付宝交易订单号教程的对话框的显示与隐藏
      courseDialogVisible:false,
      //控制扫码支付模块的显示与隐藏
      SM_payment:false
    };
  },
  computed:{
      ...mapState({
           rechargeRecord:state => state.balance.rechargeRecord,
           codeInfo:state => state.balance.codeInfo
      })
  },
  methods:{
          //获取充值记录
          getRechargeRecord(){
                this.$store.dispatch('getRechargeRecord',{num:this.queryInfo.pagesize,page:this.queryInfo.pagenum})
          },

          //获取充值二维码和余额
          getRechargeQRCode(){
                this.$store.dispatch('getRechargeQRCode')
          },

          //提交二维码充值
          QRCodeRecharge(){
              this.$refs.rechargeFormRef.validate(async valid => {
                      if(!valid) return
                      try{
                            const { rechargeAmount } = this.rechargeForm
                            await this.$store.dispatch('QRCodeRecharge',{orderbh:rechargeAmount})
                            this.$message.success('充值成功！');
                            this.getRechargeRecord()
                            this.getRechargeQRCode()
                      }catch(error){
                            this.$message.error(error.message);
                      }
              })
          },
   
          //监听pagesize改变的事件
          handleSizeChange(newSize) {
            this.queryInfo.pagesize = newSize;
            this.queryInfo.pagenum = 1;
            this.getRechargeRecord()
          },
    
          //监听页码值改变的事件
          handleCurrentChange(newPage) {
            this.queryInfo.pagenum = newPage;
            this.getRechargeRecord()
          },

          //取消支付
          cancelPayment(){
                  // 打开提示弹窗
                 this.$confirm('您确定取消支付吗？', '提示', {
                      distinguishCancelAndClose: true,
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning',
                      center: true
               })
                 .then(async () => {
                      try{
                          //  await this.$store.dispatch('removeBlacklist',buyerid)
                           this.$message.success("您已取消支付！");
                          //  this.getBlacklist()
                      }catch(error){
                           console.log(error.message)
                      }
                 })
                 .catch(action => {
                 });
          },

          //支付遇到问题
          paymentProblem(){
                this.$message.warning('请联系平台客服')
          } 
  },
  created(){
          this.getRechargeRecord()
          this.getRechargeQRCode()
  }
};
</script>

<style lang="less" scoped>
.recharge_box {
  width: 100%;
  height: auto;
  .external_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    .line {
      position: absolute;
      left: 0;
      top: 0;
      width: 12px;
      height: 100%;
      background: rgba(@themeColor, 1);
      opacity: 1;
      border-radius: 12px 0 0 12px;
    }
    .content {
      width: 100%;
      height: auto;
      margin-left: 12px;
      border-top: 1px solid #e4e7ed;
      border-right: 1px solid #e4e7ed;
      border-bottom: 1px solid #e4e7ed;
      border-left: 1px solid rgba(@themeColor, 1);
      padding: 20px 30px;
      .balance {
        font-size: 18px;
        span {
          color: rgba(@themeColor, 1);
          font-weight: 600;
        }
      }
      .QR_code_box{
              margin-top:20px;
              display:flex;
              justify-self: start;
              align-items: center;
              img{
                  width:200px;
                  height:200px;
              }
              span{
                  color: #d40b35;
                  font-size: 20px;
                  font-weight:700;
                  margin-left:10px;
              }
      }
      .recharge_form {
        margin-top: 20px;
        .r_first {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          p {
            margin-top: 8px;
          }
        }
        .r_second {
          .recharge_radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .alipay {
              width: 70px;
              height: 33px;
            }
          }
        }
        .r_third {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          p {
            color: rgb(204, 204, 204);
            margin-left: 20px;
            font-size: 15px;
          }
        }
      }
      .tips_box{
            height: auto;
            margin-top:30px;
            border-radius:8px;
            display: flex;
            justify-content:flex-start;
            align-items: center;
            padding:15px 20px;
            background:#fafafb;
            img{
                width:30px;
                height:30px;
            }
            span{
                font-size:18px;
                margin:0 20px 0 5px;
                color:#6a6a6c;
                font-weight:600;
            }
            .tips_list{
                color:#d40b35;
                font-size:16px;
                font-weight:500;
                .tip{
                    margin-bottom:5px;
                    span{
                        color:rgba(@themeColor, 1);
                        cursor: pointer;
                        font-size:14px;
                        &:hover{
                           font-size:16px;
                           color:#d40b35;
                        }
                    }
                  
                }
            }
      }
      .code_box{
          width:300px;
          height:auto;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding:0 10px 10px 10px;
          border:2px solid #316eff;
          .top{
              width:100%;
              height:50px;
              display: flex;
              justify-content: center;
              position: relative;
              .code_title{
                  font-size: 24px;
                  line-height:50px;
              }
              .cancel_payment{
                  width:60px;
                  height:25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size:8px;
                  position: absolute;
                  right:30px;
              }
          }
          .middle{
             width:220px;
             height:220px;
             overflow: hidden;
             margin-top:20px;
             img{
                  width:220px;
                  height:230px;
             }
          }
          .bottom{
              margin-top:20px;
              display: flex;
              justify-content:space-between;
              align-items:center;
           }

      }
    }
  }
}

.recharge_record_box{
    width:100%;
    height:auto;
    .record_tips{
        width: 100%;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        margin-top:5px;
        margin-bottom:5px;
        img{
            width:30px;
            height:15px;
            margin-right:5px;
        }
        p{
            color: rgb(255, 172, 28);
            font-size:15px
        }
    }
}

.ys {
  width: 300px;
}

.tips {
  color: #d40b35;
  font-size: 15px;
}
.el-card{
      margin: 20px 20px 15px 20px;
}
.el-pagination {
  margin: 20px 0;
}
</style>